<template>
  <cs-form-dialog
    :id="id"
    :model-value="showDialog"
    :heading="heading"
    :primary-action="{ label: submitBtnLabel, disabled: !canSubmit, loading: submitting }"
    :secondary-action1="{ label: 'Cancel', disabled: !canCancel }"
    @update:model-value="onDialogClose"
    @primary-click="onFormSubmit"
    @secondary1-click="onDialogClose(false)"
  >
    <template #cs-form-dialog-content>
      <v-form ref="createUserForm">
        <div class="container user-form-container">
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="userName"
                label="Name"
                color="primary"
                variant="underlined"
                :rules="[
                  validationRules.required('Name'),
                  validationRules.maxLength('Name', 150),
                  validationRules.restrictSpecialChars('Name')
                ]"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model.trim="userSurname"
                label="Surname"
                color="primary"
                variant="underlined"
                :rules="[
                  validationRules.required('Surname'),
                  validationRules.maxLength('Surname', 150),
                  validationRules.restrictSpecialChars('Surname')
                ]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="userEmail"
                class="user-editor-dlg-email"
                label="Email"
                color="primary"
                variant="underlined"
                :readonly="isEdit"
                :rules="[validationRules.required('Email'), validationRules.email]"
                :hint="
                  isEdit ? 'User email is not editable. Please create a new user instead.' : ''
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <user-roles-select-checkbox
                id="user-editor-roles"
                v-model="userSelectedRoles"
                class="user-editor-roles"
                group-id="user-editor-roles-group"
                header="Roles"
                hint="Assign roles to the user"
                multiple
              ></user-roles-select-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-url-selector
                v-if="!isEdit"
                id="user-editor-redirectUrl"
                ref="create-user-redirect-url-selector"
                label="Application Url after reset password"
                hint="User will be navigated to this url after reset their password."
                @change="onAppUrlSelect"
              >
              </app-url-selector>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </template>
  </cs-form-dialog>
</template>
<script>
import { soul } from '@/dependency-injection';
import { CSBase, CSFormDialog } from '@complispace/cs-design-system';

import AppUrlSelector from './AppUrlSelector';

import UserRolesSelectCheckbox from './UserRolesSelectCheckbox';

export default {
  name: 'UserEditorDialog',
  components: {
    'app-url-selector': AppUrlSelector,
    'cs-form-dialog': CSFormDialog,
    'user-roles-select-checkbox': UserRolesSelectCheckbox
  },
  extends: CSBase,

  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },

    heading: {
      type: String,
      default() {
        return '';
      }
    },

    connectionName: {
      type: String,
      required: true
    },

    isEdit: {
      type: Boolean,
      default() {
        return false;
      }
    },

    name: {
      type: String,
      default() {
        return '';
      }
    },

    surname: {
      type: String,
      default() {
        return '';
      }
    },

    email: {
      type: String,
      default() {
        return '';
      }
    },

    userId: {
      type: String,
      default() {
        return '';
      }
    },

    selectedRoles: {
      type: Array,
      default() {
        return [];
      }
    },

    validationRules: {
      type: Object,
      default() {
        return {
          required: (label) => (v) => !!v || `${label} is required`,
          email: (v) => {
            const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v);
            const isValidLength = v.length <= 254;
            return (
              (isValidEmail && isValidLength) ||
              'E-mail must be valid and cannot be more than 254 characters (e.g.: name@example.com)'
            );
          },
          oneOf: (label, possibleValues) => (value) =>
            possibleValues.includes(value) ||
            `${label} must be one of ${possibleValues.join(', ')}`,
          maxLength: (label, max) => (v) =>
            (v && v.length <= max) || `${label} cannot be more than ${max} characters`,
          restrictSpecialChars: (label) => (v) => {
            const hasSpecialChars = /[&<>]/.test(v);
            return (
              !hasSpecialChars || `${label} cannot contain special characters such as &, < or >`
            );
          }
        };
      }
    },
    roles: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  emits: ['submitted', 'close', 'update:modelValue'],

  data() {
    return {
      id: this.$attrs.id,
      showDialog: this.modelValue,
      canSubmitData: false,
      canCancel: true,
      userName: this.name,
      userSurname: this.surname,
      userEmail: this.email,
      userSelectedRoles: this.selectedRoles,
      redirectUrl: '',
      submitting: false
    };
  },

  computed: {
    canSubmit: {
      get() {
        const fieldsToBeSubmitted = [this.userName, this.userSurname, this.userEmail];

        if (!this.isEdit) {
          fieldsToBeSubmitted.push(this.redirectUrl);
        }

        const hasEmptyField = fieldsToBeSubmitted.some((value) => !value);

        const hasValidNames = [this.userName, this.userSurname].every(
          (value) => value.length <= 150 && !/[&<>]/.test(value)
        );

        const hasValidEmail =
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.userEmail) &&
          this.userEmail.length <= 254;

        const base = !hasEmptyField && hasValidNames && hasValidEmail && !this.isLoading();

        return !this.isEdit ? base : base && this.hasChangedFields;
      },
      set(value) {
        this.canSubmitData = value;
      }
    },

    submitBtnLabel() {
      return this.isEdit ? 'Update' : 'Create User';
    },

    hasChangedRoles() {
      return (
        [...this.selectedRoles].sort().join(', ') !== [...this.userSelectedRoles].sort().join(', ')
      );
    },

    hasChangedFields() {
      if (this.userName && this.userSurname && this.userSelectedRoles) {
        return [
          this.userName !== this.name,
          this.userSurname !== this.surname,
          this.hasChangedRoles
        ].some((it) => !!it);
      }

      return false;
    }
  },
  watch: {
    modelValue(val) {
      this.showDialog = val;
    },

    name() {
      this.userName = this.name;
    },

    surname() {
      this.userSurname = this.surname;
    },

    email() {
      this.userEmail = this.email;
    },

    selectedRoles() {
      this.userSelectedRoles = this.selectedRoles;
    },

    isEdit() {
      if (!this.isEdit) {
        this.resetValidation();
      }
    }
  },

  methods: {
    onAppUrlSelect(redirectUrl) {
      this.redirectUrl = redirectUrl || '';
    },

    resetAppUrlSelector() {
      this.$refs['create-user-redirect-url-selector'].reset();
    },

    async isValid() {
      const isValidRedirectUrl = this.isEdit ? true : !!this.redirectUrl;
      const validation = await this.$refs.createUserForm.validate();
      return isValidRedirectUrl && validation.valid;
    },

    resetValidation() {
      this.$refs.createUserForm.resetValidation();
    },

    reset() {
      this.$refs.createUserForm.reset();
    },

    changedUser() {
      return {
        name: this.userName !== this.name ? this.userName : undefined,
        surname: this.surname !== this.userSurname ? this.userSurname : undefined,
        roles: this.hasChangedRoles ? this.userSelectedRoles : undefined
      };
    },

    async validateAndSubmit() {
      const valid = await this.isValid();
      if (valid) {
        try {
          this.canSubmit = false;

          await this.onSubmit();

          this.canSubmit = true;
          this.canCancel = true;

          if (this.isEdit) {
            this.showSuccessAlert('User updated successfully');
          } else {
            this.showSuccessAlert('User created successfully');
          }

          this.onDialogClose(true);
        } catch (e) {
          this.clearLoading();

          let errorMessage = `Could not ${this.isEdit ? 'update' : 'create'} user. Please contact <href>Ideagen CompliSpace service desk</href> for support.`;
          let options = {
            href: 'https://complispace.help.ideagen.com/hc/en-gb/requests/new'
          };
          if (e.name === 'Conflict') {
            errorMessage = 'User with this email already exists';
            options = {};
          }
          this.showErrorAlert(errorMessage, true, options);
          this.canSubmit = true;
          this.canCancel = true;
        }
      }
    },

    async onSubmit() {
      this.setLoading(true);
      if (!this.isEdit) {
        const emailVerified = true;
        await soul.createUserByConnectionName(
          this.connectionName,
          this.userEmail,
          this.userName,
          this.userSurname,
          emailVerified,
          this.userSelectedRoles,
          this.redirectUrl
        );
      } else if (this.isEdit && this.hasChangedFields) {
        const changed = this.changedUser();
        await soul.updateUserById(this.userId, changed.name, changed.surname, changed.roles);
      }

      this.clearLoading();
    },

    onDialogClose(submitted) {
      this.showDialog = false;

      if (submitted) {
        this.$emit('submitted');
      }

      this.$emit('update:modelValue', false);

      this.userEmail = '';
      this.userName = '';
      this.userSurname = '';

      if (!this.isEdit) {
        this.resetAppUrlSelector();
      }

      this.resetValidation();
      this.$emit('close');
      this.submitting = false;
    },
    async onFormSubmit() {
      this.submitting = true;
      await this.validateAndSubmit();
      this.submitting = false;
    }
  }
};
</script>
<style scoped>
.user-form-container {
  margin-top: 0px;
}

.submit-btn-row {
  text-align: right;
}
</style>
